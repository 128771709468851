<template>
  <div class="main-content">
    <div class="blue-text title">確認済み注文一覧</div>
    <div id="confirmed-list" class="mt-4" v-if="confirmedTableData">
      <div v-if="confirmedTableData.data.length > 0">
        <div
          class="row pt-3 item"
          v-for="(el, i) in confirmedTableData.data"
          :key="i"
        >
          <div class="col-12 col-md-12 col-lg-12 col-xl-10 details-wrapper">
            <div class="row details">
              <div class="col-12 col-md-12 col-lg-12 col-xl-8">
                <div class="row">
                  <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">注文 ID</p>
                    <p>{{ el.reference_no }}</p>
                  </div>
                  <div class="col-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">スタイル</p>
                    <p v-if="el.style">
                      {{ el.style.name }}
                    </p>
                    <p v-else>
                    </p>
                  </div>
                  <div class="col-6 col-md-2 col-lg-2 col-xl-2 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">枚数</p>
                    <p>
                      {{
                        el.request_items.length === 1
                          ? `${el.request_items.length} 枚`
                          : `${el.request_items.length} 枚`
                      }}
                    </p>
                  </div>
                  <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">確認日</p>
                    <p class="ellipsis-1">{{ formatDate(el.confirmation_date) }}</p>
                  </div>
                  <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                    <p class="mb-1 grey-text font-weight-bold">合計購入金額</p>
                    <p>
                      {{ el.billing ? formatPrice(el.billing.amount) : 'N/A' }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-4">
                <b-carousel
                  class="img-carousel"
                  :interval="0"
                  :controls="el.request_items.length > 1 ? true : false"
                  :indicators="el.request_items.length > 1 ? true : false"
                >
                  <b-carousel-slide
                    v-for="(_el, _i) in el.request_items"
                    :key="_i"
                  >
                    <template #img>
                      <img
                        :src="getCurrentImageVersionUrl(_el)"
                        onerror="this.src='/img/image-error.png'"
                        @click="el.request_category == 2 ? openImage(getCurrentImageVersionUrl(_el)) : openModal(getCurrentImageVersionUrl(_el))"
                      />
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-12 col-xl-2 action-wrapper">
            <div class="row actions">
              <div class="col-12 mb-3">
                <router-link
                  :to="{
                    name: 'userRoute-staging-confirmed-view',
                    params: {
                      id: el.id,
                    },
                  }"
                >
                  <button class="btn btn-ds blue white-text" v-waves.light>
                    詳細を表示
                  </button>
                </router-link>
              </div>
              <div class="col-12 mb-3">
                <button
                  class="btn btn-ds blue white-text"
                  v-waves.light
                  @click="downloadImages(el.request_items)"
                >
                  画像をダウンロード
                </button>
              </div>
            </div>
          </div>
        </div>
        <pagination
          class="mt-3 mb-0"
          :data="confirmedTableData"
          :limit="limit"
          :show-disabled="showDisabled"
          :size="size"
          :align="align"
          @pagination-change-page="paginateData"
        />
      </div>
      <div v-else>
        <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
          <img src="/img/icons/ico-info.svg" alt="ico-info" />
          <div class="m-0 mt-3 grey-text non-content-text">表示できる内容がありません。</div>
        </b-card>
      </div>
    </div>
    <div class="mt-5" v-else>
      <div class="row m-0 pt-3 pb-3 item" v-for="(el, i) in 10" :key="i">
        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
          <div class="row details">
            <div class="col-12 col-md-12 col-lg-12 col-xl-9">
              <div class="row">
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                </div>
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="50%"></b-skeleton>
                </div>
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </div>
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="50%"></b-skeleton>
                </div>
                <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-3">
              <b-skeleton-img width="100%"></b-skeleton-img>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
          <div class="row actions">
            <div class="col-12 mb-3">
              <b-skeleton type="button" width="100%"></b-skeleton>
            </div>
            <div class="col-12 mb-3">
              <b-skeleton type="button" width="100%"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <image-modal @closeModal="closeModal()" v-if="isOpenModal">
      <div class="bl_image">
        <img :src="modalImageUrl">
      </div>
    </image-modal>
  </div>
</template>

<script>
// Import Services
import { globalService_DownloadIMG } from '../../services/global';
import { cus_stagingService_GetAllSR } from '../../services/customer/staging';

// Import Moment JS
import moment from 'moment';

//import common
import { getRequestItemVersions } from '../../common/Functions';
import { formatDate, formatPrice } from '../../common/Utility';

// Import Modal
import ImageModal from '../../components/customer/Image-Modal.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | Confirmed',
    };
  },

  data() {
    return {
      itemToBeRemoved: null,
      limit: 3,
      showDisabled: false,
      size: 'default',
      align: 'center',
      confirmedTableData: null,
      isMix:false,
      isOpenModal: false,
      modalImageUrl: null,
    };
  },

  components: {
    'image-modal': ImageModal,
  },

  watch: {
    limit(newVal) {
      this.limit = parseInt(newVal);
      if (this.limit < 0) {
        this.limit = 0;
      }
    },
    $route() {
      this.getResults(this.getUrlQueryParamsPage());
    },
  },

  mounted() {
    this.getResults(this.getUrlQueryParamsPage());
  },

  methods: {
    openModal(imgUrl) {
      this.modalImageUrl = imgUrl;
      this.isOpenModal = true;
    },
    closeModal(){
      this.isOpenModal = false;
    },
    openImage(imgUrl){
      this.$router.push({
        name: 'anonymousRoute-image',
        query: { image: imgUrl },
      });
    },
    formatDate,
    formatPrice,
    getUrlQueryParamsPage() {
      let currentRouteURLPrams = new URLSearchParams(location.search);
      return currentRouteURLPrams.get('page');
    },
    getResults(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      cus_stagingService_GetAllSR({ 
        page: page ? page : 1,
        status: 3, 
        token: accessToken 
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.confirmedTableData = data;
        })
        .catch((error) => {
          console.log(error);

          _this.$bvToast.toast(
            'データの取得中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );
      });
    },
    paginateData(page) {
      let _this = this;
      let currentRouteURLPrams = new URLSearchParams(location.search);
      let url_page = currentRouteURLPrams.get('page');
      if (url_page != page) {
        _this.$router.push({
          name: 'userRoute-staging-confirmed',
          query: { page: page },
        });
      }
    },
    downloadImages(requestItems) {
      let link = document.createElement('a');
      document.body.appendChild(link);

      let accessToken = localStorage.getItem('_accessToken');

      //download current confirmed image for each request items
      requestItems.forEach((ri) => {
        const { current_version } = getRequestItemVersions(ri);
        globalService_DownloadIMG({
          token: accessToken,
          path: current_version.result_file_path,
          isMix:this.isMix,
        }).then((response) => {
          let url = window.URL.createObjectURL(response.data);
          link.href = url;
          link.setAttribute(
            'download',
            `SR-${ri.staging_req_id}-${ri.id}_current_version_${current_version.delivery_date}`
          );
          link.click();
        });
      });
    },
    getCurrentImageVersionUrl(requestItem) {
      const { current_version } = getRequestItemVersions(requestItem);
      return current_version ? current_version.result_file_url : '';
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
#confirmed-list .item {
  margin-bottom: 20px;
  border-radius: 20px;
  background: #f5f5f5;
}

@media only screen and (max-width: 1199px) {
  #confirmed-list .item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 1199px) {
  #confirmed-list .item .details {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
}

#confirmed-list .item .details .img-carousel {
  height: 150px;
  width: 250px;
  margin: auto;
  background: #e9e9e9;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 1799px) {
  #confirmed-list .item .details .img-carousel {
    width: auto;
  }
}
@media only screen and (max-width: 1500px) {
  #confirmed-list .item .details .img-carousel {
    height: 130px;
  }
  #confirmed-list .item .details p {
    font-size: 14px;
  }
  .details-wrapper {
    flex: 0 0 77.444444%;
    max-width: 77.444444%;
  }
  .action-wrapper {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 1500px) {
  #confirmed-list .item {
    padding-bottom: 1rem !important;
  }
}
@media only screen and (max-width: 1199px) {
  #confirmed-list .item .details .img-carousel {
    height: 350px;
  }
  .details-wrapper {
    flex: initial;
    max-width: initial;
  }
  .action-wrapper {
    flex: initial;
    max-width: initial;
    padding-right: initial;
  }
}

@media only screen and (max-width: 767px) {
  #confirmed-list .item .details .img-carousel {
    height: 250px;
  }
  #confirmed-list {
    padding: 20px;
  }
}

.title {
  font-size: 20px;
}

.non-content-text {
  font-size: 19px;
}

.bl_image{
  display: flex;
  justify-content: center;
}
</style>
